import { useEffect } from 'react'
import window from 'window-or-global'

/**
 * This hook registers a global event listener.
 * The default target is the window object.
 *
 * Usage:
 *
 * useEventListener('keydown', myCallback)
 *
 * This will add the event listener for keydown to the window object
 *
 */

export const useEventListener = (
  eventType: string,
  handler: (e?: any) => void | EventListener | EventListenerObject,
  opts?: {
    domTarget?: EventTarget | React.RefObject<EventTarget>,
    event?: AddEventListenerOptions,
  },
) => {
  const eventOpts = opts?.event
  const domTarget = opts?.domTarget ?? window

  useEffect(() => {
    const config = {
      passive: /^(scroll|resize|wheel|touch)/.test(eventType),
      capture: false,
      ...eventOpts,
    }

    const target = 'current' in domTarget ? domTarget.current : domTarget

    // Check for target
    if (!target) return

    // add event listener
    target.addEventListener(eventType, handler, config)

    // remove event listener
    return () => {
      target.removeEventListener(eventType, handler, config)
    }
  }, [eventType, handler, domTarget, JSON.stringify(eventOpts)])
}

export default useEventListener
