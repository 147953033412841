import * as React from 'react'
import { Route } from 'react-router-dom'

export type Props = {
  code: number,
  children?: React.ReactNode,
}

type RenderProps = {
  staticContext?: any,
}

export const Status = ({ code, children }: Props) => (
  <Route
    render={({ staticContext }: RenderProps) => {
      if (staticContext) staticContext.status = code
      return children || null
    }}
  />
)

export default Status
