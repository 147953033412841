/* global window */
const isServer = typeof window === 'undefined'
const config = isServer ? process.env : (window as any).env ?? {}

/**
 * Get config values from env vars
 */
export function env (key: string, defaultVal?: string | number | boolean): string {
  if (typeof config[key] !== 'undefined') return config[key] as string
  if (typeof defaultVal !== 'undefined') return defaultVal.toString()
  throw new Error(`Config value not found: set the "${key}" environement variable.`)
}

export default env
